@tailwind base;
@tailwind components;
@tailwind utilities;

@import "colors";

body {
  background-color: $background-grey;
}

@font-face {
  font-family: 'TT Drugs Regular';
  src: local('TT Drugs Regular'), url('../../assets/fonts/TT Drugs Trial Regular.otf') format('opentype');
}

@font-face {
  font-family: 'TT Drugs Bold';
  src: local('TT Drugs Bold'), url('../../assets/fonts/TT Drugs Trial Bold.otf') format('opentype');
}

@font-face {
  font-family: 'Lovelo-Black';
  src: local('Lovelo-Black'), url("../../assets/fonts/Lovelo-Black.otf") format('opentype');
}


* {
  font-family: 'TT Drugs Regular', sans-serif;
  color: $text-base;
  font-size: 14px;
}

h3 {
  font-size: 22px;
  text-transform: uppercase;
}

p {
  font-size: 16px;
}

h1 {
  font-size: 65.4px;
  font-family: "TT Drugs Bold", sans-serif;
  text-transform: uppercase;
}

h6 {
  font-family: "TT Drugs Bold", sans-serif;
}

.bg-dark {
  background-color: $background-dark;
}

.font-lovelo {
  font-family: Lovelo-Black, sans-serif;
}